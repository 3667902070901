import axios from 'axios';
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
const authLogin = (payload) => {
  return axios.post('/authenticate/login', payload)
}
const infoRembug = (payload, token) => {
  let config = {
    headers: {
      'Token': token
    }
  }
  return axios.post('/tpl/information/rembug', payload, config)
}
const infoMember = (payload, token) => {
  let config = {
    headers: {
      'Token': token
    }
  }
  return axios.post('/tpl/information/member', payload, config)
}
const transSetoranDeposit = (payload, token) => {
  let config = {
    headers: {
      'Token': token
    }
  }
  return axios.post('/tpl/transaction/deposit', payload, config)
}
const transSetoranProses = (payload, token) => {
  let config = {
    headers: {
      'Token': token
    }
  }
  return axios.post('/tpl/transaction/process_deposit', payload, config)
}
// Anggota
const anggotaCreate = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return axios.post('/anggota/create', payload, config);
}
// Pembiayaan
const pembiayaanCreate = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return axios.post('/pengajuan/create', payload, config);
}
// Produk Tabungan
const produkTabungan = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return axios.post('/prdtabungan/read', payload, config);
}
// Produk Tabungan
const registrasiTabungan = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return axios.post('/tabungan/registrasi', payload, config);
}
const anggotaKeluar = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return axios.post('/anggotamutasi/create', payload, config);
}
const rekeningTabunganAnggota = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return axios.post('/tabungan/read', payload, config);
}
const tutupTabunganAnggota = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return axios.post('/tabungan/tutup', payload, config);
}
const readRekeningPembiayaan = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return axios.post('/registrasiakad/read', payload, config);
}
const prosesPenciran = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return axios.post('/registrasiakad/approve', payload, config);
}
const saldoAnggota = (payload, token) => {
  let config = {
    headers: {
      'token': token
    }
  }
  return axios.get('/anggotamutasi/saldo_anggota?' + payload, config);
}
export default {
  authLogin,
  infoRembug,
  infoMember,
  transSetoranDeposit,
  transSetoranProses,
  anggotaCreate,
  pembiayaanCreate,
  produkTabungan,
  registrasiTabungan,
  saldoAnggota,
  anggotaKeluar,
  rekeningTabunganAnggota,
  tutupTabunganAnggota,
  readRekeningPembiayaan,
  prosesPenciran
}